<template>
  <div>
    <van-sticky :offset-top="0">
      <demo-block :title="('')">
        <van-nav-bar :title="('用户个人信息')" :left-text="('返回')" left-arrow @click-left="this.$router.go(-1)">
          <template #right>
            <van-icon name="home" size="18" />
          </template>
        </van-nav-bar>
      </demo-block>
    </van-sticky>
    <van-form style="margin-top: 9px;">
      <van-cell-group inset>
        <van-cell v-for="item in lsFunctionList" :key="item.functionid" center :title="item.functionmemo">
          <template #right-icon>
            <van-switch v-model="item.isenable" size="20" @change="SaveOptionList('wx_function', item)" />
          </template>
        </van-cell>
      </van-cell-group>
    </van-form>

  </div>
</template>

<script>
import { Row, Col, Icon, Cell, CellGroup, Form, Field, Button, Popup, Sticky, NavBar, Area, Toast, Calendar, Radio, RadioGroup, Switch } from 'vant';
import { requestGetUserFunctionList, requestSaveUserFunctionList } from '../../api/api'

export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Form.name]: Form,
    [Field.name]: Field,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Sticky.name]: Sticky,
    [NavBar.name]: NavBar,
    [Area.name]: Area,
    [Calendar.name]: Calendar,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Switch.name]: Switch
  },
  data() {
    return {
      lsFunctionList: [],
      isconsumemsg: false,
      issavemsg: false,
      ischeckcardmsg: false,
      isattendmsg: false
    }
  },
  watch: {},
  computed: {},
  mounted() {
    this.GetUserFunctionList()
    this.isconsumemsg = (this.$store.state.userinfo.msgconsume === 1)
    this.issavemsg = (this.$store.state.userinfo.msgsave === 1)
    this.ischeckcardmsg = (this.$store.state.userinfo.msgcheck === 1)
    this.isattendmsg = (this.$store.state.userinfo.msgattend === 1)

  },
  methods: {
    async SaveOptionList (functionname,item) {
      this.isLoading = true
      const res = await requestSaveUserFunctionList({accesstoken: this.$store.state.accesstoken, paramname: functionname ,paramname1: item.functionname ,paramvalue: item.isenable?1:0})
      if (res.return_code === '0') {
          
            Toast({
                message: res.return_message,
                icon: 'fail',
            });
  
        } else {
            Toast({
                message: res.return_message,
                icon: 'fail',
            });
            item.isenable = !item.isenable
            this.isLoading = false
        }


      // console.log(item)
     //  Toast('修改成功')
    },
    async GetUserFunctionList () {
       const res = await requestGetUserFunctionList({accesstoken: this.$store.state.accesstoken})
       if (res.return_code === '0') {
          this.lsFunctionList = res.rows
          this.lsFunctionList.forEach(i => {
            i.isenable = (i.isshow === '1')
          });
          console.log(this.lsFunctionList)
          this.isLoading = false
          if (res.rows.length === 0){
            Toast({
                message: '无数据!',
                icon: 'fail',
            });
          }
  
        } else {
            Toast({
                message: res.return_message,
                icon: 'fail',
            });
            this.isLoading = false
        }
        
    } 
  }
};
</script>

<style lang="less">
.user {
  &-poster {
    width: 100%;
    height: 53vw;
    display: block;
  }

  &-group {
    margin-bottom: 15px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    .van-icon {
      display: block;
      font-size: 24px;
    }
  }
}
</style>
